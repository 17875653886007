import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

function Conduct() {
    return (
    <div>
        <Navbar />
        <div id="Conduct" className='text-box text-wrap'>
            <div className="content">
                <div className="sb_content section_padding overflow-hidden"> 
                    <h3>Code of Conduct</h3>
                    <h4 className="text-start">Scope</h4>
                    <p className="text-start">aMS Leipzig is promoting diversity and inclusion around all of our activities, including all our services and events (physical and virtual), and on all channels (e.g. websites, social media, etc.) which are run or operated by us. We support local communities, enable networking opportunities and create space for knowledge exchange.</p>
                    <p className="text-start">aMS Leipzig welcomes everyone and is dedicated to defending anybody from harassment, regardless of gender, gender identity, and expression, sexual orientation, disability, physical appearance, body size, race, age or religion. We do not tolerate harassment in any form.</p>
                    <p className="text-start">We expect all people who participate in any of our activities, attend our events or use our services to respect and follow the rules of our Code of Conduct at all times.</p>
                    <p className="text-start">Organizations, customers, exhibitors, sponsors and all of their staff, volunteers and other representatives are also subject to the Code of Conduct.</p>
                    <h4 className="text-start">Open Environment</h4>
                    <p className="text-start">A supplemental goal of this Code of Conduct is to increase an open environment by encouraging people to recognize and strengthen the relationships between our actions and their effects on our community.</p>
                    <p className="text-start">Communities mirror the societies in which they exist and positive action is essential to counteract the many forms of inequality and abuses of power that exist in society.</p>
                    <p className="text-start">If you see someone who is making an extra effort to ensure our community is welcoming, friendly, and encourages all people to contribute to the fullest extent, we want to know.</p>
                    <h4 className="text-start">Expected Behavior</h4>
                    <p className="text-start">Behave in an authentic and active way. In doing so, you contribute to the health and longevity of the aMS Leipzig community.</p>
                    <p className="text-start">Exercise consideration and respect in your speech and actions.</p>
                    <p className="text-start">Attempt collaboration before conflict.</p>
                    <p className="text-start">Refrain from demeaning, discriminatory, or harassing behavior and speech.</p>
                    <p className="text-start">Be mindful of your surroundings and of your fellow participants. Alert community leaders if you notice a dangerous situation, someone in distress, or violations of this Code of Conduct, even if they seem inconsequential.</p>
                    <h4 className="text-start">Unacceptable Behavior</h4>
                    <p className="text-start">Unacceptable behavior includes: intimidating, harassing, abusive, discriminatory, derogatory or demeaning speech or actions by any person in our online channels (e.g. Website, Slack, Event App &amp; Social Media Groups), at all related events, services or activities and in one-on-one communications carried out in the context of community business. Event venues, physical and virtual space may be shared with members of the public; please be respectful to all patrons of these locations.</p>
                    <p className="text-start">Harassment includes harmful or prejudicial verbal or written comments related to gender, sexual orientation, race, religion, disability; inappropriate use of nudity and/or sexual images; inappropriate depictions of violence; deliberate intimidation, stalking or following; harassing photography or recording; sustained disruption of talks or other activities; inappropriate physical contact, unwelcome sexual attention; reinforcement of social structure domination or encouraging any of the above behaviors.</p>
                    <p className="text-start">Sexual language and imagery will not be tolerated in any of our activities, events, services or other formats provided by us.</p>
                    <p className="text-start">In particular, organizations (customers, sponsors, booth vendors, or similar) who participate in our activities, attend our events or use our services, should not use sexualized images, sexualized material, sexualized clothing/uniforms or otherwise create a sexualized environment. They should also not organize, participate or engage in any activities which violate the Code of Conduct.</p>
                    <h4 className="text-start">Consequences of Unacceptable Behavior</h4>
                    <p className="text-start">Anyone asked to stop unacceptable behavior is expected to comply immediately.</p>
                    <p className="text-start">Unacceptable behavior from any person or organization, including customers and those with decision-making authority or any other entity, will not be tolerated. If a person or organization engages in Unacceptable Behavior or otherwise violates the Code of Conduct, we retain the right to take any action we deem appropriate, including a temporary or permanent ban and expulsion from our activities, events or services without warning and with no obligation to refund and without any liability on our part.</p>
                    <p className="text-start">As organization, we take the liberty of rejecting offers and collaboration with entities who are executing activities with connections to child labor, exploitation of people, gambling, drugs or weapons.</p>
                    <p className="text-start">If You Witness or Are Subject to Unacceptable Behavior</p>
                    <p className="text-start">If you are subject to or witness unacceptable behavior, or have any other concerns, please notify us as soon as possible. In the context of in-person events, we will also provide escorts as desired by the person experiencing distress.</p>
                    <p className="text-start">We will also be happy to help people contact hotel/venue security or local law enforcement, provide escorts, or otherwise assist those experiencing harassment to feel safe for the duration of our events.</p>
                    <h4 className="text-start">Addressing Grievances</h4>
                    <p className="text-start">If you feel you have been falsely or unfairly accused of violating this Code of Conduct, you should notify us with a concise description of your grievance. Your grievance will be handled in accordance with our existing governing policies.</p>
                    <h4 className="text-start">License and Attribution</h4>
                    <p className="text-start">This Code of Conduct is an adaptation of the Berlin Code of Conduct, which is distributed under a Creative Commons Attribution-ShareAlike 4.0 International (CC BY-SA 4.0) license. It is based on thepdx.rb Code of Conduct.</p>
                    <h4 className="text-start">Contact us with Ideas and Suggestions</h4>
                    <p className="text-start">If you have ideas on how we can create more inclusive activities, events or services, please do not hesitate to let us know.</p>
                    <p className="text-start">We value your attendance and participation in our activities and events.</p>
                </div>
            </div>        
        <Footer />
        </div>  
    </div> 
  );
}
export default Conduct;