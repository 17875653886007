import React from 'react';
import { Parallax } from 'react-parallax';

const ImageOne = () => (
  <Parallax className='image' blur={0} bgImage={'https://ams-leipzig.com/img/Leipzig-1920x1080_0008_Ebene-1.png'} strength={800} bgImageStyle={{minHeight: "100vh"}}>
    <div className='content'>
      <div id="Team" className='text-box'>     
          <div className="sb_sponsor section_padding overflow-hidden"> 
              <span className="img-txt">aMS Leipzig orga Team</span>                  
              <div className="sb_sponsor-links">
                <div className="sb_badge-links_div">
                  <img className="rounded sponsor-icon" src={'https://ams-leipzig.com/img/Christian.jpg'} alt="Orga-Foto" />
                  <h4 className="text-center">Christian Schreiber</h4>
                  <div className="sb_sponsor-ul">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">MCT & Lead Architect Microsoft 365 || Azure Devops || Fusion Development</li>
                      <li className="list-group-item"><b>Topics:</b> #Azure, DevOps, #Microsoft365, #PowerPlatform</li>
                      <li className="list-group-item"><b>Contact:</b> <a className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://www.linkedin.com/in/365-christian-schreiber/">Go to LinkedIn</a></li>
                      <li className="list-group-item"><b>Homepage:</b> <a className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://ams-leipzig.com">aMS-Leipzig</a></li>
                    </ul>                    
                  </div>
                  <img src={'https://ams-leipzig.com/img/Christian-Badge.png'} alt="Badge" className="rounded badge-icon" />                          
                </div>
                <div className="sb_badge-links_div">
                  <img className="rounded sponsor-icon" src={'https://ams-leipzig.com/img/Jonathan.jpg'} alt="Orga-Foto" />
                  <h4 className="text-center">Jonathan Lang</h4>
                  <div className="sb_sponsor-ul">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">Manager Advisory & IT-Service || BPM || HyperAutomation</li>
                      <li className="list-group-item"><b>Topics:</b> #Innovation, #Management, #Microsoft365, #DigitalSolutions</li>
                      <li className="list-group-item"><b>Contact:</b> <a className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://www.linkedin.com/in/jonathan-lang/">Go to LinkedIn</a></li>
                      <li className="list-group-item"><b>Homepage:</b> <a className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://ams-leipzig.com">aMS-Leipzig</a></li>
                    </ul>                    
                  </div>
                  <img src={'https://ams-leipzig.com/img/Christian-Badge.png'} alt="Badge" className="rounded badge-icon" />                          
                </div>
                <div className="sb_badge-links_div">
                  <img className="rounded sponsor-icon" src={'https://ams-leipzig.com/img/Patrick.jpg'} alt="Orga-Foto" />
                  <h4 className="text-center">Patrick Guimonet</h4>
                  <div className="sb_sponsor-ul">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">CEO & Founder || Microsoft 365 MVP || Enterprise Solutions Architect</li>
                      <li className="list-group-item"><b>Topics:</b> #Office365, #Microsoft365, #PowerPlatform, #MicrosoftTeams</li>
                      <li className="list-group-item"><b>Contact:</b> <a className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://www.linkedin.com/in/patrickguimonet/">Go to LinkedIn</a></li>
                      <li className="list-group-item"><b>Homepage:</b> <a className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://www.abalon-consulting.com">Abalon-Consulting</a></li>
                    </ul>                    
                  </div>
                  <img src={'https://ams-leipzig.com/img/Patrick-Badge.png'} alt="Badge" className="rounded badge-icon" />
                </div>
             </div>
           </div>
        </div>
    </div>
  </Parallax>
);

export default ImageOne;
