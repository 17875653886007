import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

function Cookie() {
    return (
    <div>
        <Navbar />
        <div id="Cookie" className='text-box text-wrap'>
            <div className="content">
                <div className="sb_content section_padding overflow-hidden"> 
                    <h3>Cookie Declaration</h3>            
                    <h4 className="text-start">What are cookies and how do we use them?</h4>
                    <p className="text-start">As most web services, we also use cookies to secure the functionality of our Services. These cookies don&rsquo;t contain Personal Data itself. You can use our Cookie Manager to edit your cookie settings for our Website at any time.</p>
                    <p className="text-start">Cookies are small files which are stored on the device or in the browser of a user. They may contain log information and visitor behavior information. As most other web services do, we also use cookies in order to secure the smooth operation of our Services, to properly handle the sign-up and login process on the Website, to enable a good user experience, as well as for advertising purposes and for analytical purposes in order to better understand how our Website and services are used.</p>
                    <p className="text-start">The cookies we use don&rsquo;t contain any personal data about the visitors of the Website and thus visitors cannot be personally identified solely with the information from the cookies.</p>
                    <p className="text-start">You may at any time delete existing cookies in your browser settings. You may also block the usage of cookies on our Website in your browser settings. However, if you do so, certain features on the Website may cease functioning, as the usage of cookies is a prerequisite for the proper operation of our web services.</p>
                </div>
            </div>        
        <Footer />
        </div>  
    </div> 
  );
}
export default Cookie;