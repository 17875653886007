import  React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <div className="sb_footer section_padding_footer">
                <div className="sb_footer-links">
                    <div className="sb_footer-links_div">
                        <h4>aMS Community</h4>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://ams.community/">Official page</a>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://sway.office.com/vwOMMcYFZuS3AljC?ref=Link">Presentation</a>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://ams.community/contact/">Contact</a>
                    </div>
                    <div className="sb_footer-links_div">
                        <h4>Sponsorship</h4>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://forms.office.com/e/vdGMmZY9AL">Platinum</a>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://forms.office.com/e/vdGMmZY9AL">Gold</a>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://forms.office.com/e/vdGMmZY9AL">Silver</a>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://forms.office.com/e/vdGMmZY9AL">Bronze</a>
                    </div>
                    <div className="sb_footer-links_div">
                        <h4>Event</h4>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="/speaker-wall">Speaker Wall</a>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="/agenda">Agenda</a>
                    </div>
                    <div className="sb_footer-links_div">
                        <h4>aMS Leipzig 2024</h4>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="/#Community">About</a>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="/imprint">Contact</a>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="/#Team">Organisers</a>
                    </div>
                    <div className="sb_footer-links_div">
                        <h4>Socialmedia</h4>
                        <div className="socialmedia">
                            <p><a href="https://web.facebook.com/aosComm/?_rdc=1&_rdr"><img src={'https://ams-leipzig.com/img/fb.png'} alt="aMS Facebook"/></a></p>
                            <p><a href="https://twitter.com/aOSComm"><img src={'https://ams-leipzig.com/img/twitter.png'} alt="aMS X"/></a></p>
                            <p><a href="https://www.linkedin.com/company/communaut%C3%A9-aos/"><img src={'https://ams-leipzig.com/img/linkedin.png'} alt="aMS LinkedIn"/></a></p>
                            <p><a href="https://www.youtube.com/channel/UCrpgGm3L25kunm2xi5cRlCA"><img src={'https://ams-leipzig.com/img/youtube.png'} alt="aMS YouTube"/></a></p>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="sb_footer-below">
                    <div className="sb_footer-copyright">
                        <p>@{new Date().getFullYear()} aMS Leipzig. All Right Reserved</p>
                    </div>
                    <div className="sb_footer-below-links">
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="/imprint"><div><p>Imprint</p></div></a>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="/privacy"><div><p>Privacy Policy</p></div></a>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="/conduct"><div><p>Code of Conduct</p></div></a>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="/cookie"><div><p>Cookie Declaration</p></div></a>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://ams-leipzig.com/doc/aMS-Leipzig-2024-Compliance-authorisation-for-the-acceptance-of-benefits.pdf"><div><p>Compliance & Acceptance of benefits [EN]</p></div></a>
                        <a className="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" href="https://ams-leipzig.com/doc/aMS-Leipzig-2024-Compliance-rechtliche-Genehmigung-zur-Annahme-von-Zuwendungen.pdf"><div><p>Compliance & Annahme von Zuwendungen [DE]</p></div></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
