import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Navbar from './Navbar';
import Footer from './Footer';

function Sponsorcontact() {
  const {param} = useParams();
  const imageUrl = `https://ams-leipzig.com/img/${param}.png`;
  const url = "https://ams-leipzig-api.azure-api.net/sponsoring/forms";
  const key = '6LeLebsoAAAAADjTZ4MBcozCKaYn4ShpqEF1p1H7';

  const [state, setState] = useState({
    captchaIsDone: false,
    sponsoringIsDone: false
  });

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    city: "",
    state: "",
    zip: "",
    company: "",
    sponsoring: ""
  });

  function submit(e) {
    e.preventDefault();
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Ocp-Apim-Subscription-Key": "ffd2c8fb69b1419281e7f077a2324953" },
      body: JSON.stringify({
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        city: data.city,
        state: data.state,
        zip: data.zip,
        company: data.company,
        sponsoring: {param}
      })
    })
    .then(response => response.json())
    .then(() => {
      setState({ ...state, sponsoringIsDone: true });
    })
    .catch(error => {
      console.error("Error submitting the form: ", error);
    });
  }

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  function onChange() {
    setState({ ...state, captchaIsDone: true });
  }

  return (
    <div>
      <Navbar />
      <div>
        {state.sponsoringIsDone ? (
          <div className="text-box-register">
            <h1>aMS Leipzig 2024 {param}ing</h1>
            <p> - Thursday, 26th September 2024 - </p>
            <div className="confirm_div">
              <h4>We are pleased to inform you that we have received your sponsorship application.</h4>
              <p>In a few moments you will receive an e-mail with a confirmation link for you to complete your application. Please click on the link within the next 48 hours.</p>
              <p>You have not received your e-mail with the link?</p>
              <p>Please check whether the e-mail has gone into your spam folder. This can occur, depending on your spam filter settings.</p>
            </div>
          </div>
        ) : (
          <div className="text-box-register">
            <img src={imageUrl} alt={param} className="sponsor-icon" />
            <h1>aMS Leipzig 2024 {param}ing</h1>
            <p>Please make sure to fill out all the required fields in the application form to become a sponsor.</p>
            <p>We would like to express our heartfelt thanks in advance for your commitment to sponsoring the upcoming aMS Leipzig 2024 event.</p>
        <div className="register_div">
        <form className="row g-3" onSubmit={(e) => submit(e)}>
          <div class="col-md-4">
              <label for="firstname" class="form-label">First name</label>
              <input onChange={(e) => handle(e)} id="firstname" value={data.firstname} type="text" class="form-control" placeholder="Your First Name (required)" required/>
            </div>
            <div class="col-md-4">
              <label for="lastname" class="form-label">Last name</label>
              <input onChange={(e) => handle(e)} id="lastname" value={data.lastname} type="text" class="form-control" placeholder="Your Last Name (required)" required/>
            </div>
            <div class="col-md-4">
              <label for="email" class="form-label">E-Mail</label>
              <input onChange={(e) => handle(e)} id="email" value={data.email} type="text" class="form-control" placeholder="Your E-Mail (required)" required/>
            </div>
            <div class="col-md-6">
              <label for="city" class="form-label">City</label>
              <input onChange={(e) => handle(e)} id="city" value={data.city} type="text" class="form-control" placeholder="Your City (required)" required/>
            </div>
            <div class="col-md-3">
              <label for="state" class="form-label">State</label>
              <input onChange={(e) => handle(e)} id="state" value={data.state} type="text" class="form-control" placeholder="Your State (required)" required/>
            </div>
            <div class="col-md-3">
              <label for="zip" class="form-label">Zip</label>
              <input onChange={(e) => handle(e)} id="zip" value={data.zip} type="text" class="form-control" placeholder="Your Zip (required)" required/>
            </div>
            <div class="col-12">
            <label for="company" class="form-label">Company Name</label>
            <input onChange={(e) => handle(e)} id="company" value={data.company} type="text" class="form-control" placeholder="Your Company Name (required)" required/>
            </div>
            <div className="col-12">
              {state.captchaIsDone ? (
                <button className="btn btn-primary" type="submit">
                  Become {param}
                </button>
              ) : (
                <div className="reCaptcha">
                  <ReCAPTCHA sitekey={key} onChange={onChange} />
                </div>
              )}
            </div>
          </form>
        </div>
        </div>)}
      </div>
      <Footer />
    </div>   
  );
}

export default Sponsorcontact;