import React from 'react';

const TextBoxTwo = () => {
    return (
        <div id="CfS" className='text-box text-wrap'>
            <div className="content">
            <div className="sb_content section_padding overflow-hidden"> 
            <h3>Call for Speakers</h3>
            <img src={'https://ams-leipzig.com/img/Silhuette-black.svg'} alt="Leipzig Silhuette" className="ams-icon-banner" />
            <p className="text-start">Hello and thank you for considering speaking at aMS Leipzig 2024. Speakers are vital to the success of our events and we are delighted that you are considering sharing your expertise with our community.&nbsp;The aMS Leipzig 2024 will take place at the <a href="https://www.mdr.de/unternehmen/mdr-studiotour-besucherfuehrung-zentrale-leipzig100.html">Mitteldeutscher Rundfunk (MDR) Tower</a> (public broadcaster serving the central regions of Germany) in the center of the <a href="https://www.leipzig.de/freizeit-kultur-und-tourismus/tourismus/sehenswuerdigkeiten/media-city">media city Leipzig</a> (Germany) on September 27th.</p>
            <h4 className="text-start">Target Audience</h4>
            <p className="text-start">We are looking for sessions that focus primarily on Teams, Microsoft365, Power Platform, Copilot &amp; AI with Azure as applied to SharePoint or Microsoft365. You may submit up to 5 sessions. However, please note that depending on the number of submissions, we will most likely select a maximum of 1 session per speaker.</p>
            <h4 className="text-start">Session Type</h4>
            <p className="text-start">We are planning the following session types:</p>
            <ul className="text-start">
                <li>45-minute general presentation sessions</li>
                <li>45-minute Sponsor Sessions (purchased as part of a sponsorship package)</li>
            </ul>
            <h4 className="text-start">Session Levels</h4>
            <ol className="text-start">
                <li>Level 100: Beginner. Little or no experience with the topic and covers topic concepts, features, functions, and benefits.</li>
                <li>Level 200: Intermediate. At least 100-level knowledge to gain a deeper understanding of the topic.</li>
                <li>Level 300: Advanced. 200-level or greater knowledge, in-depth understanding of the topic.</li>
                <li>Level 400: Expert. Assumes a deep level of knowledge and experience and a detailed, thorough understanding of the topic. Provides expert-to-expert interaction and coverage of specialized topics.</li>
            </ol>
            <h4 className="text-start">Expected timeline</h4>
            <ul className="text-start">
                <li>Early June 2024 = First speaker confirmations</li>
                <li>Late June 2024 = End of call for speakers</li>
                <li>Mid-August 2024 = final session confirmations</li>
                <li>End of August = Publish session schedule</li>
            </ul>
            <h4 className="text-start">Ethical Responsibility</h4>
            <p className="text-start">The aMS Community believes in the importance of a globally connected Dynamics community that promotes a diverse, inclusive, equal and fair industry based on the values of excellence, mutual respect, freedom of speech and progressiveness.&nbsp;Therefore, we will not tolerate any form of favoritism, offensive language, discrimination, abuse, marginalization, or offensive behavior based on gender, ethnicity, religion, background, color, creed, sexuality, gender identity, socioeconomic class, caste, disability, or age at or in connection with the conference and sessions. Please ensure that you are aware of these policies and conduct yourself accordingly.&nbsp;</p>
            <h4 className="text-start">Self or Company Promotion</h4>
            <p className="text-start">Except in sponsored sessions, self-promotion or promotion of a product, service or other offering of your company or an allied company is prohibited. Any perception of a &quot;hard sell&quot; in other sessions may result in your disqualification as a speaker at all future events.&nbsp;</p>
            <h4 className="text-start">Speaker Dinner</h4>
            <p className="text-start">Speakers are invited to a sponsored dinner the day before the event. The location is <a href="https://www.auerbachs-keller-leipzig.de/" target="_blank" rel="noopener noreferrer">Auerbachs Keller</a>, the second oldest restaurant in Leipzig, Germany. One of the city&#39;s most important wine taverns since the 16th century, it owes its worldwide reputation to Goethe&#39;s play Faust, as the first place Mephistopheles takes Faust on his travels.</p>
            <h4 className="text-start">Call for Speaker Page</h4>
            <p className="text-start">aMS Leipzig will use Sessionize as the system for receiving and selecting speakers and session. The Call for Speakers will open on 10 March 2024 and&nbsp;close 31 May 2024, but we will start selecting speakers as soon as we have some high quality content.</p>
            </div>
            </div>
            <div className="register-button">
                    <a href="https://sessionize.com/ams-leipzig-270924/" target="_blank" rel="noopener noreferrer"><img src="https://sessionize.com/landing/images/brand/banners/sessionize-banner-medium.png" alt="aMS Leipzig 2024 Call for Speaker" width="220" height="80" /></a>
            </div>
        </div>
    );
}

export default TextBoxTwo;
