import React, { useEffect } from 'react';

const Confirm = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const email = urlParams.get('email');
  const confirmationCode = urlParams.get('code');

  const data = {
    email,
    code: confirmationCode
  };

  useEffect(() => {
    const url = "https://ams-leipzig-api.azure-api.net/confirm";

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': 'ffd2c8fb69b1419281e7f077a2324953'
      },
      body: JSON.stringify(data)
    })
      .then(response => response.text())
      .then(htmlResponse => {
        document.body.innerHTML = htmlResponse;
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [data]);

  return (
    <div className='text-box'>
      <h3>You can close this page now.</h3>
    </div>
  );
}

export default Confirm;