import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

function Speakerwall() {
    return (
    <div>
        <Navbar />
        <div id="Cookie" className='text-box text-wrap'>
            <div className="content">
                <div className="sb_content section_padding overflow-hidden"> 
                    <h3>Speaker Wall</h3>
                    <p>Work in progress ...</p>
                    <img src={'https://ams-leipzig.com/img/programmer-progress.svg'} alt="Programmer in progress" className="programmer-progress" />
                    <p>... Baking some fresh code – it's in the oven, so don't burn your fingers! </p>
                </div>
            </div>        
        <Footer />
        </div>  
    </div> 
  );
}
export default Speakerwall;