import React from 'react';
import { Parallax } from 'react-parallax';

const ImageTwo = () => (


    <Parallax className='sponsor-image' blur={0} bgImage={'https://ams-leipzig.com/img/Leipzig-1920x1080_0007_Ebene-2.png'} strength={800} bgImageStyle={{minHeight:"100vh"}}>
        <div className='sponsor-content'>            
            <div id="Sponsors" className='text-box'>
                <div className="sb_sponsor section_padding overflow-hidden"> 
                <span className="img-txt">Call for Sponsors</span>                   
                    <div className="sb_sponsor-links">
                        <div className="sb_sponsor-links_div">
                            <img src={'https://ams-leipzig.com/img/PlatinumSponsor.png'} alt="PlatinumSponsor" className="sponsor-icon" />
                            <h4>Become a Platinum Sponsor</h4>
                            <div className="sb_sponsor-ul">
                            <ul>
                                <li>Presentation In All Marketing Materials</li>
                                <li>Social Media Thanks</li>
                                <li>Logo & Link On Website</li>
                                <li>Central Placement For Logo</li>
                                <li>2 Seats @ Speaker Dinner</li>
                                <li>2 x 45 Minute Sessions</li>
                                <li>Platinum Area Sponsor Booth</li>
                            </ul>
                            </div>
                            <a href="https://forms.office.com/e/vdGMmZY9AL" target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark sponsor-btn" role="button">
                            Contact Now
                            </a>
                        </div>
                        <div className="sb_sponsor-links_div">
                            <img src={'https://ams-leipzig.com/img/GoldSponsor.png'} alt="GoldSponsor" className="sponsor-icon" />
                            <h4>Become a Gold Sponsor</h4>
                            <div className="sb_sponsor-ul">
                            <ul>
                                <li>Presentation In All Marketing Materials</li>
                                <li>Social Media Thanks</li>
                                <li>Logo & Link On Website</li>
                                <li>Central Placement For Logo</li>
                                <li>1 x 45 Minute Sessions</li>
                                <li>Gold Area Sponsor Booth</li>
                            </ul>
                            </div>
                            <a href="https://forms.office.com/e/vdGMmZY9AL" target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark sponsor-btn" role="button">
                            Contact Now
                            </a>
                        </div>
                        <div className="sb_sponsor-links_div">
                            <img src={'https://ams-leipzig.com/img/SilverSponsor.png'} alt="SilverSponsor" className="sponsor-icon" />
                            <h4>Become a Silver Sponsor</h4>
                            <div className="sb_sponsor-ul">
                            <ul>
                                <li>Presentation In All Marketing Materials</li>
                                <li>Social Media Thanks</li>
                                <li>Logo & Link On Website</li>
                                <li>Logo In All Sessions</li>
                                <li>Silver Area Sponsor Booth</li>
                            </ul>
                            </div>
                            <a href="https://forms.office.com/e/vdGMmZY9AL" target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark sponsor-btn" role="button">
                            Contact Now
                            </a>
                        </div>
                        <div className="sb_sponsor-links_div">
                            <img src={'https://ams-leipzig.com/img/BronzeSponsor.png'} alt="BronzeSponsor" className="sponsor-icon" />
                            <h4>Become a Bronze Sponsor</h4>
                            <div className="sb_sponsor-ul">
                            <ul>
                                <li>Social Media Thanks</li>
                                <li>Logo & Link On Website</li>
                                <li>Logo In All Sessions</li>
                                <li>Logo On Lanyards</li>
                            </ul>
                            </div>
                            <a href="https://forms.office.com/e/vdGMmZY9AL" target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark sponsor-btn" role="button">
                            Contact Now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Parallax>
);

export default ImageTwo;
