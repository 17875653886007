import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/home';
import Register from './components/register';
import Confirm from './components/confirm';
import Sponsorcontact from './components/sponsorcontact';
import Imprint from './components/imprint';
import Privacy from './components/privacy';
import Conduct from './components/conduct';
import Cookie from './components/cookie';
import Agenda from './components/agenda';
import Speakerwall from './components/Speakerwall';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/contact/:param" element={<Sponsorcontact />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/conduct" element={<Conduct />} />
          <Route path="/cookie" element={<Cookie />} />
          <Route path="/agenda" element={<Agenda />} />
          <Route path="/speaker-wall" element={<Speakerwall />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);