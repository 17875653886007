import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Navbar from './Navbar';
import Footer from './Footer';

function Register() {
  const socialpost = 'https://ams-leipzig.com/attachment/attendee-social-post-ams-leipzig-just-registered.zip';
  const ics = 'https://ams-leipzig.com/attachment/aMS_Leipzig_2024.ics';
  const [isLoading, setIsLoading] = useState(false);
  const url = "https://ams-leipzig-api.azure-api.net/registration/forms";
  const key = '6LeLebsoAAAAADjTZ4MBcozCKaYn4ShpqEF1p1H7';

  const [state, setState] = useState({
    captchaIsDone: false,
    registrationIsDone: false
  });

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    city: "",
    state: "",
    zip: "",
    company: "",
    jobrole: "",
    topics: "",
    remark: "",
    Attend: "",
    Newsletter: "",
    Sponsorinfo: ""

  });

  function submit(e) {
    setIsLoading(true);
    e.preventDefault();
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Ocp-Apim-Subscription-Key": "ffd2c8fb69b1419281e7f077a2324953" },
      body: JSON.stringify({
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        city: data.city,
        state: data.state,
        zip: data.zip,
        company: data.company,
        jobrole: data.jobrole,
        topics: data.topics,
        remark: data.remark,
        Attend: data.Attend,
        Newsletter: data.Newsletter,
        Sponsorinfo: data.Sponsorinfo
      })
    })
      .then(response => response.json())
      .then();
        setState({ ...state, registrationIsDone: true });
        setIsLoading(false);      
  }

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  function onChange() {
    setState({ ...state, captchaIsDone: true });
  }

  return (
    <div>
      <Navbar />
      <div>
        {state.registrationIsDone ? (
          <div className="text-box-register">
            <h1>aMS Leipzig 2024</h1>
            <div className="confirm_div">
              <h4>PLEASE CONFIRM YOUR REGISTRATION</h4>
              <p>In a few moments you will receive an e-mail with a confirmation link for you to complete your registration. Please click on the link within the next 48 hours.</p>
              <p>You have not received your e-mail with the link?</p>
              <p>Please check whether the e-mail has gone into your spam folder. This can occur, depending on your spam filter settings.</p>
              <a href={socialpost} class="btn btn-outline-secondary btn-lg" tabindex="1" role="button" aria-disabled="false" download>Download social post</a>
              <br />
              <a href={ics} class="btn btn-outline-secondary btn-lg" tabindex="1" role="button" aria-disabled="false" download>Download .ics File</a>
            </div>
          </div>
        ) : (
          <div className="text-box-register">
            <h1>aMS Leipzig Registration</h1>
            <p> - Thursday, 26th September 2024 - </p>
            <p>Seats are limited! Register now for free to guarantee your spot.</p>
        <div className="register_div">
        <form className="row g-3" onSubmit={(e) => submit(e)}>
            <div class="col-md-4 text-start">
              <label for="firstname" class="form-label h6">Your First name</label>
              <input onChange={(e) => handle(e)} id="firstname" value={data.firstname} type="text" class="form-control" placeholder="Your First Name (required)" required/>
            </div>
            <div class="col-md-4 text-start">
              <label for="lastname" class="form-label h6">Your Last name</label>
              <input onChange={(e) => handle(e)} id="lastname" value={data.lastname} type="text" class="form-control" placeholder="Your Last Name (required)" required/>
            </div>
            <div class="col-md-4 text-start">
              <label for="email" class="form-label">Your E-Mail</label>
              <input onChange={(e) => handle(e)} id="email" value={data.email} type="text" class="form-control" placeholder="Your E-Mail (required)" required/>
            </div>
            <div class="col-md-4 text-start">
              <label for="city" class="form-label h6">City</label>
              <input onChange={(e) => handle(e)} id="city" value={data.city} type="text" class="form-control" placeholder="Your City (optional)" required/>
            </div>
            <div class="col-md-4 text-start">
              <label for="state" class="form-label h6">State</label>
              <input onChange={(e) => handle(e)} id="state" value={data.state} type="text" class="form-control" placeholder="Your State (optional)"/>
            </div>
            <div class="col-md-4 text-start">
              <label for="zip" class="form-label">Zip</label>
              <input onChange={(e) => handle(e)} id="zip" value={data.zip} type="text" class="form-control" placeholder="Your Zip (optional)"/>
            </div>
            <div class="col-md-6 text-start">
              <label for="company" class="form-label h6">Your Company Name</label>
              <input onChange={(e) => handle(e)} id="company" value={data.company} type="text" class="form-control" placeholder="Your Company Name (optional)"/>
            </div>
            <div class="col-md-6 text-start">
              <label for="jobrole" class="form-label h6">Your Job Role</label>
              <input onChange={(e) => handle(e)} id="jobrole" value={data.jobrole} type="text" class="form-control" placeholder="Your Job Role (optional)"/>
            </div>
            <div class="col-12 text-start">
              <label for="topics" class="form-label h6">Your topics of interest</label>
              <input onChange={(e) => handle(e)} id="topics" value={data.topics} type="text" class="form-control" placeholder="Your topics of interest (optional)"/>
            </div>
            <div class="col-12 text-start">
              <label for="remark" class="form-label h6">Any remark you would like to share with us?</label>
              <input onChange={(e) => handle(e)} id="remark" value={data.remark} type="text" class="form-control" placeholder="Share your remarks (optional)"/>
            </div>

            <div class="col-12 text-start">
            <p className="text-start h6">How do you will attend the aMS Leipzig 2024 Event?</p>
              <select onChange={(e) => handle(e)} id="Attend" value={data.Attend} class="form-select" aria-label="Choice attend" required>
                <option Selectet="Attend"></option>
                <option value="InPerson">In Person</option>
                <option value="Online">online</option>
              </select>
            </div>
            <div class="col-12 text-start">
            <p className="text-start h6">I accept to share my contact infos with event sponsors in order for them to send me some details about their products. (You can cancel this confirmation at any time)</p>
              <select onChange={(e) => handle(e)} id="Sponsorinfo" value={data.Sponsorinfo} class="form-select" aria-label="Choice Sponsorinfo" required>
                <option Selectet="Sponsorinfo"></option>
                <option value="Sponsorinfo-true">Yes</option>
                <option value="Sponsorinfo-false">No</option>
              </select>
            </div>
            <div class="col-12 text-start">
              <p className="text-start h6">I accept to share my contact infos with aMS Community in order to receive information on next events. (You can cancel this confirmation at any time)</p>
              <select onChange={(e) => handle(e)} id="Newsletter" value={data.Newsletter} class="form-select" aria-label="Choice Newsletter" required>
                <option Selectet="Newsletter"></option>
                <option value="Newsletter-true">Yes</option>
                <option value="Newsletter-false">No</option>
              </select>
            </div>

            <div className="col-12">
              {state.captchaIsDone ? (
              <button className="btn btn-primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <>
                      <button class="btn btn-primary" type="button" disabled="">
                        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        <span role="status">Loading...</span>
                      </button>
                  </>
                ) : (
                  "Register"
                )}
              </button>
                ) : (
                <div className="reCaptcha">
                  <ReCAPTCHA sitekey={key} onChange={onChange} />
                </div>
              )}
            </div>
          </form>
        </div>
        </div>)}
      </div>
      <Footer />
    </div>   
  );
}

export default Register;