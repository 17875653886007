import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

function Imprint() {
    return (
    <div>
        <Navbar />
        <div id="Imprint" className='text-box text-wrap'>
            <div className="content">
                <div className="sb_content section_padding overflow-hidden"> 
                    <h3>Imprint</h3>
                    <h4 className="text-start">Information according to &sect; 5 TMG</h4>
                    <p className="text-start">Christian Alexander Schreiber</p>
                    <p className="text-start">Feldstrasse 10</p>
                    <p className="text-start">04442 Zwenkau</p>
                    <h4 className="text-start">Contact</h4>
                    <p className="text-start">Phone: +491735896903</p>
                    <p className="text-start">E-Mail: christian.schreiber@ams-leipzig.com</p>
                    <h4 className="text-start">Editorially responsible</h4>
                    <p className="text-start">Christian Alexander Schreiber</p>
                    <h4 className="text-start">Legal notice</h4>
                    <p className="text-start">This website is operated by:&nbsp;Christian Alexander Schreiber @&nbsp;aMS-Leipzig.com</p>
                    <h4 className="text-start">Disclaimer</h4>
                    <p className="text-start">The content and information on this website is provided by aMS-Leipzig. It has been compiled with the utmost care and to the best of our knowledge. We have made every attempt to ensure the accuracy of the information provided. However, while we try to keep the information up to date and correct, we do not assume any liability or warranty of any kind about the completeness, accuracy, reliability, suitability, currentness or availability regarding the information, products, services, graphics or other content contained on our website for any purpose. Any reliance on the information provided on our website is at your own risk. In no event will we be liable for any kind of loss or damage arising in connection with the use of this website.</p>
                    <p className="text-start">This website contains links to websites of third parties (external links). These links serve for informational purpose only and the content on these external links is not under our control. We don&#39;t bear any liability for the information or any other form of content provided by these external websites.</p>
                    <p className="text-start">The content published on this website is protected by copyright law. Duplication, redistribution, processing or any form of use of information or content published on this website is prohibited. You may not use any parts of the website or its content without our express written permission.</p>
                </div>
            </div>        
        <Footer />
        </div>  
    </div> 
  );
}
export default Imprint;