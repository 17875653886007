import React from 'react';
import { Parallax } from 'react-parallax';

const cookieStorage = {
    getItem: (key) => {
        const cookies = document.cookie
            .split(';')
            .map(cookie => cookie.split('='))
            .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
        return cookies[key];
    },
    setItem: (key, value) => {
        document.cookie = `${key}=${value}`;
    },
};

const consentPropertyName = 'userConsent';

const shouldShowPopup = () => !cookieStorage.getItem(consentPropertyName);
const saveToStorage = () => cookieStorage.setItem(consentPropertyName, true);

window.onload = () => {
    const consentPopup = document.getElementById('consent-popup');
    const acceptBtn = document.getElementById('accept');

    const acceptFn = () => {
        saveToStorage();
        consentPopup.classList.add('hidden');
    };

    acceptBtn.addEventListener('click', acceptFn);

    if (shouldShowPopup()) {
        setTimeout(() => {
            consentPopup.classList.remove('hidden');
        }, 2000);
    }
};

const ImageOne = () => (
    <Parallax className='image' blur={0} bgImage={'https://ams-leipzig.com/img/Leipzig-1920x1080_0000_Ebene-9.png'} strength={800} bgImageStyle={{ minHeight: "100vh" }}>
        <div className='content'>
            <div className="logo-container">
                <img src={'https://ams-leipzig.com/img/aMSLogo.svg'} alt="aMSLogo" className="logo-img" />
                <span className="img-txt">aMS LEIPZIG</span>
                <h3 className="img-txt"> Friday, 27th September 2024</h3>
                <h3 className="img-txt">as part of the The Azure, Microsoft 365 & SharePoint global community</h3>
                <div className="register-button">
                    <a className="btn btn-light" href="https://forms.office.com/e/hCgAEN32fJ" role="button" target="_blank" rel="noopener noreferrer">Register Now. It's free</a>
                </div>
                <div id="consent-popup" className="consent-popup hidden">
                    <p>By using this website you agree to our use of cookies. Please read our <a href="/privacy" className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">privacy policy</a> for more information and <a id="accept" href="/#Accept" className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">accept</a> our use of cookies.</p>
                </div>
            </div>
        </div>
    </Parallax>
);

export default ImageOne;
