import React, { Component } from "react";
import { MenuData } from "./MenuData";

class Navbar extends Component {
    state = { clicked: false };
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked });
    };
    scrollToSection = (sectionId) => {
        if (sectionId === "Home") {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        } else {
            const section = document.getElementById(sectionId);
            const navbarHeight = 120;
            if (section) {
                const topOffset = section.getBoundingClientRect().top + window.scrollY - 100;
                window.scrollTo({
                    top: topOffset - navbarHeight,
                    behavior: "smooth",
                });
            }
        }
    };

    handleLinkClick = (item) => {
        if (item.URL !== "/") {
          window.location.href = item.URL;
        } else {
          this.scrollToSection(item.id);
        }
      };

  render() {
    return (
        <nav className="NavbarItems">
            <div className="ams-title">
                <img src={'https://ams-leipzig.com/img/aMSLogo.svg'} alt="aMS Logo" className="logo" onClick={() => this.scrollToSection("Home")}/>
                <h1 className="navbar-title" onClick={() => this.scrollToSection("Home")}>aMS LEIPZIG 2024</h1>
            </div>
            <div className="menu-icons" onClick={this.handleClick}>
            <i>
                {this.state.clicked ? (
                    <img className="navbaricon" src={'https://ams-leipzig.com/img/close-square.svg'} alt="navbaricon" />
                ) : (
                    <img className="navbaricon" src={'https://ams-leipzig.com/img/menu-dots.svg'} alt="navbaricon" />
                )}
            </i>
            </div>
            <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                {
                    MenuData.map((item, index) => {
                        return(
                            <li key={index}>
                                <a href={item.URL} className={item.cName} onClick={() => this.scrollToSection(item.id)}>{item.title}</a>
                            </li>
                        )
                    })
                }
            </ul>
        </nav>
    );
  }
}

export default Navbar;