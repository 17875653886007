import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

function Agenda() {
    return (
    <div>
        <Navbar />
        <div id="Cookie" className='text-box text-wrap'>
            <div className="content">
                <div className="sb_content section_padding overflow-hidden"> 
                    <h3><b>Event Agenda 27th September 2024</b></h3>
                    <h6 className='center'><a href="https://www.google.com/maps/place/Mitteldeutscher+Rundfunk+(MDR)/@51.316587,12.3822263,842m/data=!3m1!1e3!4m19!1m12!4m11!1m3!2m2!1d12.3864667!2d51.3163328!1m6!1m2!1s0x47a6f9c9b74fff2f:0x21766e6392ebe380!2sKantstra%C3%9Fe+71+-+73,+04275+Leipzig!2m2!1d12.385892!2d51.3163765!3m5!1s0x47a6f9c9b74fff2f:0x21766e6392ebe380!8m2!3d51.3163765!4d12.385892!16s%2Fg%2F1hc1r8hyw?hl=de&entry=ttu
                    " target="_blank" rel="noopener noreferrer">Mitteldeutscher Rundfunk (MDR) - Kantstrasse 71, 04275 Leipzig</a></h6>
                    <table>
                        <tr>
                            <th className="time-column">Time</th>
                            <th className="room-column">Room 1</th>
                            <th className="room-column">Room 2</th>
                        </tr>
                        <tr>
                            <td className="time-column">08:30 - 08:45</td>
                            <td colspan="2">Opening & Coffee break & Networking</td>
                        </tr>
                        <tr>
                            <td className="time-column">08:45 - 09:00</td>
                            <td colspan="2">Introduction & Expectations</td>
                        </tr>
                        <tr>
                            <td className="time-column">09:00 - 09:45</td>
                            <td colspan="2">Opening Keynote - Microsoft</td>
                        </tr>
                        <tr>
                            <td className="time-column">09:45 - 10:30</td>
                            <td className="room-column">
                                <div className="session-container">
                                    <div className="session-avatar">
                                        <img className="speaker-avatar-img" src={'https://ams-leipzig.com/img/speaker-avatar.png'} alt="Speaker Avatar" />
                                    </div>
                                    <div className="session-text">
                                        <p className="session-title">Clipchamp Mastery: Videos professionell erstellen und teilen im M365-Umfeld</p>
                                        <p>German</p>
                                        <p>Sandra Kiel</p>
                                        <p>Session Description</p>
                                    </div>
                                </div>
                            </td>
                            <td className="room-column">
                                <div className="session-container">
                                    <div className="session-avatar">
                                        <img className="speaker-avatar-img" src={'https://ams-leipzig.com/img/speaker-avatar.png'} alt="Speaker Avatar" />
                                    </div>
                                    <div className="session-text">
                                        <p className="session-title">Extend Copilot for Microsoft 365 with Plugins</p>
                                        <p>English</p>
                                        <p>Yves Habersaat</p>
                                        <p>Session Description</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="time-column">10:30 - 11:15</td>
                            <td className="room-column">
                                <div className="session-container">
                                    <div className="session-avatar">
                                        <img className="speaker-avatar-img" src={'https://ams-leipzig.com/img/speaker-avatar.png'} alt="Speaker Avatar" />
                                    </div>
                                    <div className="session-text">
                                        <p className="session-title">Teams Immersive & Mesh : Welcome to my virtual world</p>
                                        <p>English</p>
                                        <p>Clément Serafin</p>
                                        <p>Session Description</p>
                                    </div>
                                </div>
                            </td>
                            <td className="room-column">
                                <div className="session-container">
                                    <div className="session-avatar">
                                        <img className="speaker-avatar-img" src={'https://ams-leipzig.com/img/speaker-avatar.png'} alt="Speaker Avatar" />
                                    </div>
                                    <div className="session-text">
                                        <p className="session-title">Deep dive into the Zero Trust journey : Securing your MS Entra ID with our Zero Trust </p>
                                        <p>English / German</p>
                                        <p>Seyfallah Tagrerout & Jean-Francois Aprea</p>
                                        <p>Session Description</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="time-column">11:15 - 11:30</td>
                            <td colspan="2">Coffee break & Networking</td>
                        </tr>
                        <tr>
                            <td className="time-column">11:30 - 12:15</td>
                            <td className="room-column">
                                <div className="session-container">
                                    <div className="session-avatar">
                                        <img className="speaker-avatar-img" src={'https://ams-leipzig.com/img/speaker-avatar.png'} alt="Speaker Avatar" />
                                    </div>
                                    <div className="session-text">
                                        <p className="session-title">To Adopt or Not: Making an Informed Power Platform Decision and Establish a Solid Foundation</p>
                                        <p>English</p>
                                        <p>David Uhlmann</p>
                                        <p>Session Description</p>
                                    </div>
                                </div>
                            </td>
                            <td className="room-column">
                                <div className="session-container">
                                    <div className="session-avatar">
                                        <img className="speaker-avatar-img" src={'https://ams-leipzig.com/img/speaker-avatar.png'} alt="Speaker Avatar" />
                                    </div>
                                    <div className="session-text">
                                        <p className="session-title">Teams + DeepLink + PowerApps + PowerAutomate + FlowBot + SharePoint Lists = Document </p>
                                        <p>English</p>
                                        <p>Alaa Bitar</p>
                                        <p>Session Description</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="time-column">12:15 - 13:00</td>
                            <td colspan="2">Lunch break & Networking</td>
                        </tr>
                        <tr>
                            <td className="time-column">13:00 - 13:45</td>
                            <td className="room-column">
                                <div className="session-container">
                                    <div className="session-avatar">
                                        <img className="speaker-avatar-img" src={'https://ams-leipzig.com/img/speaker-avatar.png'} alt="Speaker Avatar" />
                                    </div>
                                    <div className="session-text">
                                        <p className="session-title">Business transformation through Power Applications</p>
                                        <p>English</p>
                                        <p>Anouck Fierens & Steve Dalby</p>
                                        <p>Session Description</p>
                                    </div>
                                </div>
                            </td>
                            <td className="room-column">
                                <div className="session-container">
                                    <div className="session-avatar">
                                        <img className="speaker-avatar-img" src={'https://ams-leipzig.com/img/speaker-avatar.png'} alt="Speaker Avatar" />
                                    </div>
                                    <div className="session-text">
                                        <p className="session-title">Building GenAI Applications with Azure AI</p>
                                        <p>German</p>
                                        <p>Alexander Wachtel</p>
                                        <p>Session Description</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="time-column">13:45 - 14:30</td>
                            <td className="room-column">
                                <div className="session-container">
                                    <div className="session-avatar">
                                        <img className="speaker-avatar-img" src={'https://ams-leipzig.com/img/speaker-avatar.png'} alt="Speaker Avatar" />
                                    </div>
                                    <div className="session-text">
                                        <p className="session-title">Die NIS 2 Richtlinie in der praktischen Umsetzung und Anwendung bei Microsoft 365</p>
                                        <p>German</p>
                                        <p>Raphael Koellner</p>
                                        <p>Session Description</p>
                                    </div>
                                </div>
                            </td>
                            <td className="room-column">
                                <div className="session-container">
                                    <div className="session-avatar">
                                        <img className="speaker-avatar-img" src={'https://ams-leipzig.com/img/speaker-avatar.png'} alt="Speaker Avatar" />
                                    </div>
                                    <div className="session-text">
                                        <p className="session-title">Session 10</p>
                                        <p>English / German</p>
                                        <p>Session Speaker</p>
                                        <p>Session Description</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="time-column">14:30 - 14:45</td>
                            <td colspan="2">Coffee break & Networking</td>
                        </tr>
                        <tr>
                            <td className="time-column">14:45 - 15:30</td>
                            <td className="room-column">
                                <div className="session-container">
                                    <div className="session-avatar">
                                        <img className="speaker-avatar-img" src={'https://ams-leipzig.com/img/speaker-avatar.png'} alt="Speaker Avatar" />
                                    </div>
                                    <div className="session-text">
                                        <p className="session-title">Session 11</p>
                                        <p>English / German</p>
                                        <p>Session Speaker</p>
                                        <p>Session Description</p>
                                    </div>
                                </div>
                            </td>
                            <td className="room-column">
                                <div className="session-container">
                                    <div className="session-avatar">
                                        <img className="speaker-avatar-img" src={'https://ams-leipzig.com/img/speaker-avatar.png'} alt="Speaker Avatar" />
                                    </div>
                                    <div className="session-text">
                                        <p className="session-title">Session 12</p>
                                        <p>English / German</p>
                                        <p>Session Speaker</p>
                                        <p>Session Description</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="time-column">15:30 - 15:35</td>
                            <td colspan="2">Pause & Room Change</td>
                        </tr>
                        <tr>
                            <td className="time-column">15:35 - 16:25</td>
                            <td colspan="2">Closing Keynote - Microsoft</td>
                        </tr>
                    </table>
                </div>
            </div>        
        <Footer />
        </div>  
    </div> 
  );
}
export default Agenda;
