import React from 'react';
import Navbar from './Navbar';
import ImageOne from './ImageOne';
import ImageTwo from './ImageTwo';
import ImageThree from './ImageThree';
import TextBoxOne from './TextBoxOne';
import TextBoxTwo from './TextBoxTwo';
import TextBoxThree from './TextBoxThree'; 
import Footer from './Footer';

function Home() {
  return (
    <div>
      <Navbar />
      <ImageOne />
      <TextBoxOne />
      <ImageTwo />
      <TextBoxTwo />
      <ImageThree />
      <TextBoxThree />
      <Footer />
    </div>
  );
}

export default Home