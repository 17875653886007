import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

function Privacy() {
    return (
    <div>
        <Navbar />
        <div id="Privacy" className='text-box text-wrap'>
            <div className="content">
                <div className="sb_content section_padding overflow-hidden"> 
                    <h3>Privacy- Policy</h3>                    
                    <p className="text-start">aMS Leipzig, Germany, (hereinafter referred to as &ldquo;aMS Leipzig&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; &ldquo;our&rdquo;) provides you with various services (see below) and operates the website ams-leipzig.com and its subdomains (collectively the &ldquo;Website&rdquo;).</p>
                    <p className="text-start">Services we offer to you include the usage of our Website, the participation in events, the consumption of text, video and audio content, the participation in various activities organized by us, the provision of consultancy and support for various matters related to our services or our company, or any other services we may perform based on your request (hereinafter collectively referred to as &ldquo;Services&rdquo;).</p>
                    <p className="text-start">Your privacy and the protection of your personal data is important to us. Therefore we want to explain what data we collect when you use our Services, how we use that data and what rights you have in relation to it.</p>
                    <p className="text-start">We collect, use, process, store, transfer, treat or handle all data in accordance with EU and German data protection laws and regulations.</p>
                    <p className="text-start">Legal Basis for Processing Personal Data under GDPR</p>
                    <p className="text-start">We may process Personal Data under the following conditions:</p>
                    <h4 className="text-start">Personal Data:</h4>
                    <p className="text-start">You have given your consent for processing Personal Data for one or more specific purposes.</p>
                    <h4 className="text-start">Performance of an Event registration:</h4>
                    <p className="text-start">Provision of Personal Data is necessary for the performance of an agreement with you and/or for any Events thereof.</p>
                    <h4 className="text-start">Legal obligations:</h4>
                    <p className="text-start">Processing Personal Data is necessary for compliance with a legal obligation to which aMS Leipzig is subject.</p>
                    <h4 className="text-start">Vital interests:</h4>
                    <p className="text-start">Processing Personal Data is necessary in order to protect your vital interests or of another natural person.</p>
                    <h4 className="text-start">Public interests:</h4>
                    <p className="text-start">Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in aMS Leipzig.</p>
                    <h4 className="text-start">Legitimate interests:</h4>
                    <p className="text-start">Processing Personal Data is necessary for the purposes of the legitimate interests pursued by aMS Leipzig.</p>
                    <p className="text-start">In any case, we will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to participate in our Events.</p>
                    <h4 className="text-start">What data do we collect?</h4>
                    <p className="text-start">We collect personally identifiable information (&ldquo;Personal Data&rdquo;) that you voluntarily provide to us when you use our Services. We only collect Personal Data that is reasonably necessary to provide you with our Services. Some data, such as usage data, device data and log data, is automatically collected by our servers when you use our Services.</p>
                    <h4 className="text-start">Personal Data:</h4>
                    <p className="text-start">We may ask you for personal data when you use our Services (e.g. use online forms, submit requests, submit sponsor requests, or similar). The specific data we collect depends on the Services you use and may include your name, email address, date of birth, mailing address, phone number, job title, work experience, academic background, technical skills, social media profiles, and similar. All personal data that you provide to us must be true, accurate and complete.</p>
                    <h4 className="text-start">Usage Data, Device Data, Log Data:</h4>
                    <p className="text-start">We may automatically collect certain data when you use our Services in order to secure and maintain the operation of these Services, for internal analytical purposes and for internal reporting purposes. This data may include your activity on our Website (such as date and time of your visits and usage of our Services, pages you visit on the Website, and similar), IP address, browser information (such as browser type and settings), hardware and device information (such as device identifiers, operating system, hardware model), language settings, location information, referring URLs, diagnostic data, ISP information and error reports. Please be informed that while this data does not reveal your personal identity, it may be possible to identify individual persons when different types of data are combined with each other.</p>
                    <h4 className="text-start">When and how do we collect data?</h4>
                    <p className="text-start">We collect data from you when you perform certain activities while you use our Services. You directly provide us with most of the data we collect.</p>
                    <h4 className="text-start">These activities include, but are not limited to, the following use cases:</h4>
                    <ul className="text-start">
                        <li>Visit and use our Website</li>
                        <li>Register an account on our Website</li>
                        <li>Setup, populate, complete or edit your account information or account settings</li>
                        <li>Participate in events, conferences, workshops, webinars, or similar activities</li>
                        <li>Engage in Q&amp;A sessions</li>
                        <li>Leave comments</li>
                        <li>Fill out forms on our Website</li>
                        <li>Participate in any of our competitions, surveys, promotions, raffles or similar activities</li>
                        <li>Mention us on social media</li>
                        <li>Contact us and communicate with us via email, chat, social media or similar ways of communication</li>
                    </ul>
                    <p className="text-start">We may collect limited publicly available information also from public databases, social media, and other external sources. For example, when you interact with us on social media, we receive Personal Data about you (such as your name). The type of information depends on the third party provider (e.g. the social media platform) and may include your name, email address, job title, or similar information.</p>
                    <h4 className="text-start">How will we use your data?</h4>
                    <p className="text-start">We only process and use the collected data when we have legitimate reasons to do so. These reasons are based on legitimate business interests in order to perform and maintain our Services, the performance of our contract with you and our legal obligations and responsibilities.</p>
                    <h4 className="text-start">We process and use the data we collect:</h4>
                    <ul className="text-start">
                        <li>to provide you with the Services you have requested (e.g. giving you access to our events or content, matching you with other users or entities of our Service)</li>
                        <li>to fulfill our legal and regulatory obligations</li>
                        <li>to enable account registration and login processes</li>
                        <li>to manage user accounts</li>
                        <li>to give you access to certain features, tools and services on our Website</li>
                        <li>to enable user-to-user communication</li>
                        <li>to communicate with you</li>
                        <li>to request feedback from you or to provide feedback to you</li>
                        <li>to send you service, product, feature, release or similar information</li>
                        <li>to enable you to customize your experience when you use our Services</li>
                        <li>to provide you with access to certain features on our Website</li>
                        <li>to provide you with personalized content based on your account preferences</li>
                        <li>to run surveys, competitions, raffles or similar activities</li>
                        <li>to improve our Services</li>
                        <li>for analytical and market research purposes</li>
                        <li>for internal records and operational purposes</li>
                        <li>to provide you with marketing and promotional information (based on your consent and in accordance with your marketing preferences and existing legal regulations)</li>
                        <li>to perform targeted advertising and provide personalized content</li>
                        <li>to secure and protect our Services, including fraud detection and prevention</li>
                        <li>to enforce legal regulations from official authorities</li>
                        <li>to enforce our terms and conditions, website terms of use and/or similar terms and policies related to our Services</li>
                    </ul>
                    <h4 className="text-start">How and how long do we store your Personal Data?</h4>
                    <p className="text-start">We undertake all reasonable measures to securely store the data we collect. We will retain your Personal Data only as long as necessary for the purposes outlined in this Privacy Policy.</p>
                    <p className="text-start">We undertake all reasonable technical and organizational measures to securely store your Personal Data. However, due to the nature of technology and the internet, it is not possible to guarantee absolute security despite all technical measures. Therefore, we are not liable for any actions of third parties.</p>
                    <p className="text-start">Your Personal Data is retained as long as necessary for the purposes described in this Privacy Policy and no longer than 1 month after the termination of an Event. Some data may be retained for longer periods in order to fulfill our regulatory and legal obligations.</p>
                    <p className="text-start">As soon as we have no legitimate reasons to retain your data anymore, we will delete your Personal Data or in certain reasonable cases anonymize it. When we keep backups of data, we will do it in a way that the backed up Personal Data is isolated from any further usage or processing until a complete deletion is possible.</p>
                    <h4 className="text-start">Do we share your Personal Data with third parties?</h4>
                    <p className="text-start">We only share your Personal Data with third parties with your explicit consent, when it is necessary or when we are enforced by law to do so.</p>
                    <p className="text-start">In order to perform our Events, we may transmit your Personal Data to third parties depending on the Service you use and when it is necessary due to the nature of the respective service.</p>
                    <p className="text-start">Group companies: We may share your information with our group companies (subsidiaries or parent companies) in order to perform our Services and provide you with the products, services and tools you have requested.</p>
                    <p className="text-start">Third-party services providers, suppliers, vendors: We use and subcontract third party service providers, suppliers, vendors or similar entities to perform services on our behalf. Where necessary, we may share data with such third parties in order to be able to facilitate, operate, maintain and secure our own Services. This may be the case for third party services such as: server hosting, payment processing, CRM, customer service, communication services, event services (ticketing, access control, badge printing, or similar services which are in the nature of events), accounting services, data analysis, or similar. Such third parties are carefully selected by us and are bound by our directives and corresponding regulations to protect the data we share with them. Further, they are only allowed to use the shared data for purposes they have been contracted for and to fulfill their contractual obligations for the performance of services on our behalf. Unless otherwise described in this notice or without your explicit consent, these third parties are not entitled to use transmitted Personal Data for their own promotional purposes or any other purposes beyond the scope of the fulfillment of their contractual obligations with us.</p>
                    <p className="text-start">Business partners or other users of our Services: Your data may be shared with other users of our Services where this is needed in order to perform the service you requested us for or when it is in the nature of the respective service. For example, when you publicly post comments or participate in Events, some data (such as your name) will be visible to other users or the public.</p>
                    <p className="text-start">As described, we only share your Personal Data (or parts of it), with your consent, based on the activities and actions you set when you use our Services, based on legitimate interests and in order to perform the services you have asked us for.</p>
                    <p className="text-start">If we transmit Personal Data outside of the European Economic Area, we do so only to countries for which the EU commission has declared that these countries have an appropriate level of data protection, other adequate measures exist (such as EU Standard Contractual Clauses) or you have given us consent to do so.</p>
                    <p className="text-start">Also, we may share your Personal Data with third parties if we are required by law to do so.</p>
                    <h4 className="text-start">Marketing</h4>
                    <p className="text-start">With your consent, we may send you marketing and promotional information. You have the option to opt-out of such communications at any time.</p>
                    <p className="text-start">We may, with your consent, provide you with marketing and promotional information from us or from our business partners. After you have given us such consent, you will have the possibility to opt-out from such communication at any time (e.g. by using the unsubscribe function in our newsletter, by selecting to unsubscribe in your account settings or by contacting us via email or the respective Website form). The ways for such marketing and promotional information may include email, phone or mailing communication.</p>
                    <p className="text-start">Analytics, Social Media Plugins, Social Media Logins, Retargeting</p>
                    <p className="text-start">Social Media Plugins: We may use social media plugins on specific pages on our Website in order to enable you to use certain social media features (such as sharing or commenting content, engaging with content or displaying content). When you visit a page on our Website which includes such a social media plugin, your browser will establish a connection to the server of the respective social media provider. The social media provider will gain knowledge that you have accessed the corresponding page on our Website. This information, together with your IP address, will be transmitted directly to a server operated by the respective social media provider (which, for social media providers from the US, may be a server in the USA). Social media providers we may use on our Website include Facebook Inc., Linkedin Inc./Linkedin Corporation, Twitter Inc., GitHub Inc., Google LLC, YouTube LLC, Vimeo Inc., or their respective group companies. We have no control over the manner in which these social media services collect or process data. Therefore, we recommend that you read the privacy policy of the respective social media provider for more information. You can use your browser settings or specific browser add-ons to prevent the loading of such social media plugins.</p>
                    <p className="text-start">Social Media Logins: We may give you the option to register or login to our Website with your social media profiles (such as Facebook, Linkedin, Twitter, GitHub, Google). If you choose to use this option, we will receive certain profile data from your social media provider. The specific data we receive depends on the social media provider (in most cases it includes at least your name, email address, profile picture and similar information) and may be used in order to properly perform our Services (e.g. for login purposes, account management, to populate your profile on the Website, or similar). We recommend that you read the privacy policy of the respective social media provider for more information.</p>
                    <h4 className="text-start">Do we offer our Services to minors?</h4>
                    <p className="text-start">We don&rsquo;t address anyone under the age of 18 with our Services.</p>
                    <p className="text-start">We don&rsquo;t knowingly or intentionally collect Personal Data from people under the age of 18. When you use our Services, you represent that you are not under the age of 18 or that you are a parent of a minor and consent to such a minor&#39;s use of our Services. When we encounter that we have collected data from a person of an age less than 18, we will delete the respective account and undertake appropriate measures to delete the respective data we have collected.</p>
                    <h4 className="text-start">Privacy policies of other websites</h4>
                    <p className="text-start">Our Privacy Policy applies only to our own Website and Services and we are not responsible for websites of third parties.</p>
                    <p className="text-start">Our website contains links to other websites. Our Privacy Policy applies only to our Website. If you click on a link to another website, you should read their privacy policy. We don&rsquo;t accept any liability for anything that happens on third-party websites.</p>
                    <h4 className="text-start">What are your data protection rights under GDPR?</h4>
                    <p className="text-start">You have the right of access, rectification, erasure, restriction of processing, objection to processing and data portability related to your Personal Data.</p>
                    <p className="text-start">It is important to us to make sure you are fully aware of all of your data protection rights. In relation to your Personal Data, you are entitled to the following:</p>
                    <ul className="text-start">
                        <li>The right to access: You have the right to request us for copies of your Personal Data.</li>
                        <li>The right to rectification: You have the right to request that we correct any information you believe is inaccurate. You also have the right to request us to complete the information you believe is incomplete.</li>
                        <li>The right to erasure: You have the right to request that we erase your Personal Data, under certain conditions.</li>
                        <li>The right to restrict processing: You have the right to request that we restrict the processing of your Personal Data, under certain conditions.</li>
                        <li>The right to object to processing: You have the right to object to us processing of your Personal Data, under certain conditions.</li>
                        <li>The right to data portability: You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                        <li>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</li>
                    </ul>
                    <h4 className="text-start">Exercising of Your GDPR Data Protection Rights</h4>
                    <p className="text-start">You may exercise your rights of access, rectification, cancellation and opposition by contacting us. Please note that we may ask you to verify your identity before responding to such requests.</p>
                    <p className="text-start">You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, if your are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.</p>
                    <h4 className="text-start">Changes to our Privacy Policy</h4>
                    <p className="text-start">We may update our Privacy Policy when necessary to comply with relevant regulations.</p>
                    <p className="text-start">We keep our Privacy Policy under regular review and reserve the right to update or amend the Privacy Policy at any time. We indicate the date of the last update on the Privacy Policy at the bottom of this page.</p>
                </div>
            </div>        
        <Footer />
        </div>  
    </div> 
  );
}
export default Privacy;