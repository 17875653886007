import React from 'react';

const TextBoxThree = () => {
    return (
        <div id="Community" className='text-box text-wrap'>
            <div className="content">
                <div className="sb_content section_padding">
                <h3>The Community</h3>
                <img src={'https://ams-leipzig.com/img/banner.png'} alt="aMS Banner" className="ams-icon-banner" />
                <h4 className="text-start">What is aMS</h4>
                <p className="text-start">aMS, which stands for Azure Microsoft 365 SharePoint, is an international community of professionals working on the Microsoft 365 platform.&nbsp;It&#39;s a non-profit, independent organization whose members aim to share their knowledge and experience on Microsoft collaboration solutions and applications.</p>
                <h4 className="text-start">Initially founded</h4>
                <p className="text-start">The aMS community was created on January 1st, 2016 by a group of French-speaking professionals from France, Belgium and Canada. It is open to people already involved in community activities through co-optation.&nbsp;It has now expanded worldwide with events in Canada, Southeast Asia, Africa and Germany.</p>                
                <h4 className="text-start">Our mission</h4>
                <ul className="text-start">
                    <li>Facilitate sharing and exchange between members and all users of the Microsoft collaboration platform: to this end, we organize or support live and online events (public meetings, conferences, online meetings, sharing and exchange networks, and any other means to achieve this goal).</li>
                    <li>To develop and deepen the knowledge and expertise of members and all users of the platform: to this end, we produce or support the production of high-quality content in all forms (technical articles, blog posts, podcasts, webcasts, and any other means to achieve this goal).</li>
                </ul>
                </div>
                <div className="register-button">
                    <a href="https://www.youtube.com/@amscommunitycommunauteams"><img className="YouTube" src={'https://ams-leipzig.com/img/subscribe.png'} alt="Follow us by Youtube" /></a>
                </div>
            </div>
        </div>
    );
}

export default TextBoxThree ;
