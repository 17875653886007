export const MenuData = [
    {
        title: "Home",
        id: "Home",
        URL: "/",
        cName: "nav-links",
        icon: "fa-solid fa-house-user"
    },
    {
        title: "Expectations",
        id: "Expectations",
        URL: "/#Expectations",
        cName: "nav-links",
        icon: "fa-solid fa-at"
    },
    {
        title: "Sponsors",
        id: "Sponsors",
        URL: "/#Sponsors",
        cName: "nav-links",
        icon: "fa-solid fa-handshake"
    },
    {
        title: "CfS",
        id: "CfS",
        URL: "/#CfS",
        cName: "nav-links",
        icon: "fa-solid fa-users"
    },
    {
        title: "Team",
        id: "Team",
        URL: "/#Team",
        cName: "nav-links",
        icon: "fa-solid fa-globe"
    },    
    {
        title: "Community",
        id: "Community",
        URL: "/#Community",
        cName: "nav-links",
        icon: "fa-solid fa-globe"
    }  
]