
import React from 'react';

const TextBoxOne = () => {
    return (
        <div id="Expectations" className='text-box'>
            <div className="content">
                <div className="sb_content section_padding">
                <h3>What you can expect</h3>
                <div className="event-location">
                <a href="https://www.google.com/maps/place/Mitteldeutscher+Rundfunk+(MDR)/@51.316587,12.3822263,842m/data=!3m1!1e3!4m19!1m12!4m11!1m3!2m2!1d12.3864667!2d51.3163328!1m6!1m2!1s0x47a6f9c9b74fff2f:0x21766e6392ebe380!2sKantstra%C3%9Fe+71+-+73,+04275+Leipzig!2m2!1d12.385892!2d51.3163765!3m5!1s0x47a6f9c9b74fff2f:0x21766e6392ebe380!8m2!3d51.3163765!4d12.385892!16s%2Fg%2F1hc1r8hyw?hl=de&entry=ttu
" target="_blank" rel="noopener noreferrer"><img className="event-location-img" src={'https://ams-leipzig.com/img/event-location.png'} alt="Mitteldeutscher Rundfunk (MDR) - Kantstrasse 71, 04275 Leipzig"/></a>
                <h6 className="ImageRights">Aerial view of the MDR headquarters in Leipzig. Image rights: MDR/Martin Jehnichen</h6>
                <h6>Event Location: <a href="https://www.google.com/maps/place/Mitteldeutscher+Rundfunk+(MDR)/@51.316587,12.3822263,842m/data=!3m1!1e3!4m19!1m12!4m11!1m3!2m2!1d12.3864667!2d51.3163328!1m6!1m2!1s0x47a6f9c9b74fff2f:0x21766e6392ebe380!2sKantstra%C3%9Fe+71+-+73,+04275+Leipzig!2m2!1d12.385892!2d51.3163765!3m5!1s0x47a6f9c9b74fff2f:0x21766e6392ebe380!8m2!3d51.3163765!4d12.385892!16s%2Fg%2F1hc1r8hyw?hl=de&entry=ttu
" target="_blank" rel="noopener noreferrer">Mitteldeutscher Rundfunk (MDR) - Kantstrasse 71, 04275 Leipzig</a></h6>
                <p>aMS Leipzig 2024 is a free one-day Microsoft Cloud Community event that brings technology and community leaders to Leipzig to share their knowledge with the global community.</p>
                </div>
                    <div className="sb_content-links">
                        <div className="sb_content-links_div">
                            <img src={'https://ams-leipzig.com/img/social.svg'} alt="Insights Logo" className="content-icon" />
                            <h3>Social</h3>
                            <p>Expand your professional network by attending our event, where you will have the outstanding opportunity to meet and connect with individuals from around the world. Interact with professionals from diverse backgrounds, exchange ideas, and develop valuable relationships. Enhance your network, gain fresh perspectives, and establish alliances that will advance your career and unlock new prospects.</p>
                        </div>
                        <div className="sb_content-links_div">
                            <img src={'https://ams-leipzig.com/img/insights.svg'} alt="Insights Logo" className="content-icon" />
                            <h3>Insights</h3>
                            <p>This event provides valuable insights into the technology stack from diverse angles, enabling attendees to explore the untapped potential of Microsoft Cloud.  Industry thought leaders will be speaking on how Microsoft Cloud empowers businesses and careers.</p>
                        </div>
                        <div className="sb_content-links_div">
                            <img src={'https://ams-leipzig.com/img/enlightenment.svg'} alt="Insights Logo" className="content-icon" />
                            <h3>Enlightenment</h3>
                            <p>Opt for a diverse range of sessions that exhibit Microsoft Cloud SaaS solutions like Azure, Microsoft 365, SharePoint Online, AI services, collaboration tools, and application development. Enhance your understanding of these innovative technologies and explore how they can expedite data-driven decision making and innovation in your organisation.</p>
                        </div>                        
                        <div className="sb_content-links_div">
                            <img src={'https://ams-leipzig.com/img/experience.svg'} alt="Insights Logo" className="content-icon" />
                            <h3>Experience</h3>
                            <p>The culturally rich hub of Leipzig, Germany is known as the 'City of Music' due to its prestigious musical heritage, featuring globally acclaimed composers such as Johann Sebastian Bach. Notably, Leipzig University, one of Germany's oldest universities, has a celebrated history of academic excellence. The fusion of tradition and festivity in Leipzig offers an extraordinary and distinctive cultural experience.</p>
                        </div>
                    </div>
                </div>
                <div className="register-button">                    
                    <a class="btn btn-dark" href="https://ams-leipzig-270924.sessionize.com" role="button" target="_blank" rel="noopener noreferrer">Event Agenda 2024 & App</a>
                </div>
            </div>
        </div>
    );
}

export default TextBoxOne;
